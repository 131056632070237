<template>
<div class="contactUs">
  <CompanyProfileBanner :index="'6'" :imageUrl="imageUrl"></CompanyProfileBanner>
  <BreadcrumbAndSubtag
    :tabList="tabList"
    :activeNowPath="activeNowPath"
    @changeActiveTabFun="changeActiveTabFun"
  ></BreadcrumbAndSubtag>
  <div class="contentDiv">
    <div class="contents">
      <div class="textContent">
        <h1>联系方式</h1>
        <p>请通过以下方式与我们联系，我们的客户服务将在24小时内与您联系，感谢您的支持！</p>
        <div class="way">
          <div class="wayList">
            <img src="@/assets/image/contactUs2.png" />
            <div class="wayListContent">
              <p><b>联系电话</b></p>
              <p>{{ contactUs.join('、') }}</p>
            </div>
          </div>
          <div class="wayList">
            <img src="@/assets/image/contactUs3.png" />
            <div class="wayListContent">
              <p><b>联系邮箱</b></p>
              <p>{{ companyEmail }}</p>
            </div>
          </div>
          <div class="wayList">
            <img src="@/assets/image/contactUs1.png" />
            <div class="wayListContent">
              <p><b>公司地址</b></p>
              <p>{{ companyAddress }}</p>
            </div>
          </div>
        </div>
      </div>
      <!--<span style="color: red;display: inline-block;margin-left: 3px; vertical-align: top;">*</span>-->
      <div class="formContent">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0" class="demo-ruleForm">
          <el-form-item label="" prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入姓名" clearable></el-input>
          </el-form-item>
          <el-form-item label="" prop="tel">
            <el-input v-model="ruleForm.tel" placeholder="请输入手机号" clearable></el-input>
          </el-form-item>
          <el-form-item label="" prop="email">
            <el-input v-model="ruleForm.email" placeholder="请输入邮箱" clearable></el-input>
          </el-form-item>
          <el-form-item label="" prop="desc">
            <el-input v-model="ruleForm.desc" type="textarea" placeholder="请输入您的需求" :rows="4" clearable></el-input>
            <span style="color: red;display: inline-block;margin-left: 3px; vertical-align: top;">*</span>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitToFun">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
  <footerMain></footerMain>
</div>
</template>

<script>
import CompanyProfileBanner from '@/components/companyProfileBanner'
import BreadcrumbAndSubtag from '@/components/breadcrumbAndSubtag'
import footerMain from '@/components/footerMain'

export default {
  name: "contactUs",
  data() {
    return {
      contactUs: contactUs,
      companyEmail: companyEmail,
      companyAddress: companyAddress,
      tabList: [
        {key: 'contactWay', name: '联系方式'},
        {key: 'firmMail', name: '企业邮箱'},
      ],
      imageUrl: '',
      activeNowPath: 'contactWay',
      ruleForm: {
        name: '',
        tel: '',
        email: '',
        desc: ''
      },
      rules: {

      }
    }
  },
  components: {
    CompanyProfileBanner,
    BreadcrumbAndSubtag,
    footerMain,
  },
  created() {
    this.loadResourceOfPageFun()
  },
  methods: {
    changeActiveTabFun(val) {
      console.log('val', val)
      this.activeNowPath = val
    },
    loadResourceOfPageFun() {
      api.getResourceOfPage({pageId: 18, locationType: 1}).then((res) => {
        if(res.code === 100) {
          this.imageUrl = resAddressPrefix + res.data[0].images[0].imageUrl
        }else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        if(err.msg) {
          this.$message.error(err.msg);
        }
      });
    },
    submitToFun() {
      if(this.ruleForm.desc === '') {
        this.$message.error("请输入您的需求再进行提交！");
        return
      }
      const _params = {
        comment: this.ruleForm.desc
      }
      if(this.ruleForm.name !== '') {
        _params.name =this.ruleForm.name
      }
      if(this.ruleForm.tel !== '') {
        _params.tel = this.ruleForm.tel
      }
      if(this.ruleForm.email !== '') {
        _params.email = this.ruleForm.email
      }
      api.saveComment(_params).then((res) => {
        if(res.code === 100) {
          this.$message.success(res.data);
        }else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        if(err.msg) {
          this.$message.error(err.msg);
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.contactUs {
  ::v-deep .el-input,
  ::v-deep .el-textarea{
    width: calc(100% - 10px);
  }
  .contentDiv {
    margin: auto;
    display: table;
    vertical-align: middle;
    .contents {
      width: 1200px;
      padding: 4em 0 2em 0;
      display: table-cell;
      vertical-align: middle;
      .textContent,
      .formContent {
        width: 50%;
        text-align: left;
        display: inline-block;
        vertical-align: middle;
      }
      .textContent {
        h1 {
          margin: 0;
          padding-bottom: 20px;
        }
        p {
          margin: 0;
          font-size: 14px;
          color: rgb(153, 153, 153);
        }
        .way {
          padding: 3em 0;
          .wayList {
            padding: 10px 0;
            img {
              width: 50px;
              height: 50px;
              display: inline-block;
              vertical-align: middle;
            }
            .wayListContent {
              margin-left: 20px;
              display: inline-block;
              vertical-align: middle;
              p {
                color: #000000;
                line-height: 140%;
              }
            }
          }
        }
      }
    }
  }

  ::v-deep .el-form-item {
    margin-bottom: 12px;
  }
  ::v-deep .el-input__inner {
    height: 50px;
    line-height: 50px;
  }
  ::v-deep .el-input__inner,
  ::v-deep .el-textarea__inner{
    font-size: 14px;
    color: #434343;
    background-color: #e7e7e7;
    border-color: transparent;
    &::placeholder{
      color: #434343;
    }

  }

}
</style>
