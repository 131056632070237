<template>
<div class="breadcrumbAndSubtag">
  <div class="contents">
    <div class="breadcrumb">
        <span class="indicatorText">
          <i class="el-icon-s-home"></i>
          当前位置：
        </span>
        <el-breadcrumb separator-class="el-icon-d-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <template v-if="breadcrumbs">
            <el-breadcrumb-item :to="{ path: breadcrumbs.path }">{{ breadcrumbs.name }}</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: this.$route.path }">{{ breadcrumbs ? breadcrumbs.childName : '' }}</el-breadcrumb-item>
          </template>
          <template v-else>
            <el-breadcrumb-item :to="{ path: this.$route.path }">{{ this.$route.name }}</el-breadcrumb-item>
          </template>
        </el-breadcrumb>
    </div>
    <div v-show="tabList" class="subtag">
      <el-menu :default-active="activeNowPath" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <template v-for="list in tabList">
          <el-menu-item :key="list.key" :index="list.key">
            <span slot="title">{{ list.name }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "breadcrumbAndSubtag",
  props: ['tabList', 'activeNowPath', 'breadcrumbs'],
  methods: {
    handleSelect(path, keyPath) {
      if(path === 'firmMail') {
        window.open('http://mail.transenergy-tech.com/', '_blank');
        window.location.reload()
      } else {
        this.$emit('changeActiveTabFun', path)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.breadcrumbAndSubtag {
  width: 100%;
  height: 65px;
  background: #F8F8F8;
  .contents{
    display: flex;
    width: 1200px;
    height: 65px;
    line-height: 65px;
    margin: auto;
    justify-content: space-between;
    .breadcrumb {
      .indicatorText {
        float: left;
        .el-icon-s-home{
          color: #004AAE;
        }
      }
      ::v-deep .el-breadcrumb {
        color: #5a5a5a;
        float: left;
        height: 65px;
        line-height: 65px;
        .el-breadcrumb__item {
          color: #5a5a5a;
        }
        .el-breadcrumb__separator {
          color: #5a5a5a;
        }
      }
    }
    .subtag {
      ::v-deep .el-menu {
        background-color: rgba(255,255,255,0);
      }
      ::v-deep .el-menu.el-menu--horizontal {
        border-bottom: none;
      }
      ::v-deep .el-menu--horizontal>.el-menu-item.is-active{
        color: #FFFFFF;
        border-bottom: none;
        background: #004AAE;
      }
      ::v-deep .el-menu--horizontal>.el-menu-item{
        height: 65px;
        line-height: 65px;
        color: #5a5a5a;
        border-radius: 0px;
      }
      ::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
      ::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
        color: #FFFFFF;
        border-bottom: none;
        background: #004AAE;
      }
    }
  }
}
</style>
