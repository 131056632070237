<template>
  <div class="companyProfileBanner">
<!--    <img v-if="index === '1'" src="@/assets/image/bannerList1.jpg" />-->
<!--    <img v-else-if="index === '2'" src="@/assets/image/bannerList2.jpg" />-->
<!--    <img v-else-if="index === '3'" src="@/assets/image/bannerList3.jpg" />-->
<!--    <img v-else-if="index === '4'" src="@/assets/image/bannerList4.jpg" />-->
<!--    <img v-else-if="index === '5'" src="@/assets/image/bannerList5.jpg" />-->
<!--    <img v-else-if="index === '6'" src="@/assets/image/bannerList6.jpg" />-->
    <img v-if="imageUrl !== ''" :src="imageUrl" />
    <img v-else src="@/assets/image/bannerList6.jpg" />
  </div>
</template>

<script>
export default {
  name: "companyProfileBanner",
  props: ['index', 'imageUrl'],
  watch: {
    index(n, o){
      console.log(n);
      console.log(o);
    }
  },
}
</script>

<style scoped lang="scss">
.companyProfileBanner {
  height: 260px;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
